import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import config from "../config";

const VerifyEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Added loading state

  const handleLogin = () => {
    window.location.href = "/login";
  };

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/auth/verify-email/${email}`
        );
        if (response.data.status) {
          setMessage("Email verified successfully. You can now login.");
        } else {
          setMessage("Email verification failed. Please try again.");
        }
      } catch (error) {
        console.error("Error verifying email", error);
        setMessage("Error verifying email. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    verifyEmail();
  }, [email]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-[#aabeff] to-white font-sans">
      <div className="bg-white/90 rounded-3xl p-10 shadow-lg text-center max-w-[400px] w-[90%]">
        {isLoading ? (
          // Loading spinner
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-opacity-70 mb-5"></div>
            <p className="text-gray-600 text-base leading-relaxed">
              Verifying your email, please wait...
            </p>
          </div>
        ) : (
          <>
            <div className="text-6xl text-green-500 mb-5">✓</div>
            <h2 className="text-2xl font-bold text-gray-700 mb-5">
              Email Verification
            </h2>
            <p className="text-gray-600 text-base leading-relaxed mb-4">
              {message || "Your email has been verified."}
            </p>
            {message.includes("successfully") && (
              <button
                onClick={handleLogin}
                className="bg-[#6e8efb] text-white px-6 py-3 text-base rounded-full 
                   cursor-pointer transition-all duration-300 ease-in-out 
                   hover:bg-[#5c7cfa] hover:-translate-y-0.5 hover:shadow-lg"
              >
                Login
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
