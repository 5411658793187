import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import NotificationCart from "./NotificationCart";
import BackButton from "./BackButton";
import CustomerLogin from "./CustomerLogin";
import apiClient from "../api/CustomerClient";

const CustomerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cart = location.state?.cart || [];
  const restaurant_id = location.state?.restaurant_id;
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [formMode, setFormMode] = useState("login");
  const [isLoginVisible, setLoginVisible] = useState(false);
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("customerData");
    return storedData && storedData !== "undefined"
      ? JSON.parse(storedData)
      : null;
  });
  const [orderType, setOrderType] = useState("pickup");
  const [table_no, settable_number] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [geoLocation, setGeoLocation] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [restaurantDetail, setRestaurantDetail] = useState("");
  const [showRestaurantDetails, setShowRestaurantDetails] = useState(false);

  // Desktop event handlers
  const handleRestaurantDetailsHover = () => {
    setShowRestaurantDetails(true);
  };

  const handleRestaurantDetailsLeave = () => {
    setShowRestaurantDetails(false);
  };

  // Mobile event handler
  const handleRestaurantDetailsClick = () => {
    setShowRestaurantDetails((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 100;
      setIsScrolled(window.scrollY > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!geoLocation) {
      getCustomerLocation();
    }
    fetchRestaurantDetails();
    const storedUserData = localStorage.getItem("customerData");
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setUserData(parsedData);
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Error parsing stored user data:", error);
        localStorage.removeItem("customerData");
      }
    }
  }, []);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("CustomerToken");
      if (token) {
        try {
          const payload = JSON.parse(atob(token.split(".")[1]));
          const expirationTime = payload.exp * 1000;

          if (Date.now() >= expirationTime) {
            localStorage.removeItem("customerData");
            localStorage.removeItem("CustomerToken");
            setUserData(null);
            setIsLoggedIn(false);
            showNotification("Session expired. Please login again.", "info");
          }
        } catch (error) {
          console.error("Error checking token expiration:", error);
        }
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(interval);
  }, []);

  const getCustomerLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setGeoLocation({ latitude, longitude });
        },
        (err) => {
          showNotification("Failed to get location", "error");
          console.error(err);
        }
      );
    }
  };

  const showNotification = (message, status) => {
    setNotificationMessage(message);
    setNotificationStatus(status);
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
    }, 9000);
  };

  const fetchRestaurantDetails = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/restaurant/get-restaurant/${restaurant_id}`
      );
      console.log(response.data.data);
      setRestaurantDetail(response.data.data);
    } catch (error) {
      showNotification("Error fetching restaurant details", "error");
    }
  };

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
    settable_number("");
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/login`,
        userData
      );
      setUserData(response.data);
      setIsLoggedIn(true);
      localStorage.setItem("customerData", JSON.stringify(response.data));
      localStorage.setItem("CustomerToken", response.data.token);
      setErrorMessage("");
    } catch (error) {
      console.error("Error logging in:", error);
      setErrorMessage("Invalid email or password");
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setErrorMessage("Please accept the Terms and Conditions");
      return;
    }
    if (userData.password !== passwordConfirmation) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/create`,
        { ...userData, restaurant_id: restaurant_id }
      );
      setUserData(response.data.data);
      localStorage.setItem("customerData", JSON.stringify(response.data.data));
      setIsLoggedIn(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error signing up:", error);
      setErrorMessage(
        error.response?.data?.message ||
          "Error creating account. Please try again."
      );
    }
  };

  const handleGuestLogin = async (e) => {
    e.preventDefault();
    if (!termsAccepted) {
      setErrorMessage("Please accept the Terms and Conditions");
      return;
    }
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/create`,
        { ...userData, is_guest: true, restaurant_id: restaurant_id }
      );
      console.log("Token received:", response.data.data.token); // Add this line
      setUserData(response.data.data);
      localStorage.setItem("customerData", JSON.stringify(response.data.data));
      localStorage.setItem("CustomerToken", response.data.data.token); // Modified this line
      setIsLoggedIn(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "Error creating account. Please try again."
      );
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  // const location = useLocation();
  const showLoginButtonPaths = ["/menu-display", "/cart", "/customer_details"];

  const handleLoginClick = () => setLoginVisible(true);
  const handleCloseLogin = () => setLoginVisible(false);
  const handleLoginSuccess = (data) => {
    setUserData(data);
    setIsLoggedIn(true);
    setLoginVisible(false);
    window.location.reload();
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/customer/forgot-password`,
        { email: resetEmail }
      );
      showNotification(
        response.data.message || "Password reset link sent",
        "success"
      );
      setFormMode("login");
      setErrorMessage("");
    } catch (error) {
      showNotification(
        error.response?.data?.message || "Error resetting password",
        "error"
      );
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!isLoggedIn && formMode !== "guestLogin") {
      showNotification("Please log in or continue as guest", "error");
      return;
    }

    if (orderType === "dine-in" && !table_no) {
      showNotification(
        "Please select a table number for dine-in order",
        "error"
      );
      return;
    }

    if (orderType === "pickup" && !geoLocation) {
      getCustomerLocation();
      return;
    }

    setIsSubmitting(true);
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    const token = localStorage.getItem("CustomerToken");

    const orderItems = cart.map((item) => ({
      menu_item_id: item.menu_id,
      quantity: item.quantity,
    }));

    try {
      const response = await apiClient.post(
        `/customer/place-order`,
        {
          restaurant_id: Number(restaurant_id),
          customer_details: userData,
          order_items: orderItems,
          order_type: orderType,
          table_no: orderType === "dine-in" ? table_no : null,
          is_guest: formMode === "guestLogin",
          order_time: formattedTime,
          customer_location: {
            latitude: geoLocation?.latitude,
            longitude: geoLocation?.longitude,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        localStorage.removeItem("currentCart");
        navigate(
          `/customer/order-status?orderId=${response.data.order_id}&restaurant_id=${restaurant_id}`
        );
      }
    } catch (error) {
      showNotification(
        error.response?.data?.message || "Error placing order",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4 md:mt-5">
      <BackButton />
      <>
        <div className="max-w-7xl mx-auto px-4 mt-18 md:mt-15">
          <div className="max-w-6xl mx-auto relative">
            <div
              className={`${
                isScrolled ? "fixed top-0" : "relative"
              } left-0 right-0 z-40 bg-white/95 backdrop-blur-sm shadow-lg transition-all duration-300`}
              onMouseEnter={handleRestaurantDetailsHover}
              onMouseLeave={handleRestaurantDetailsLeave}
              onClick={handleRestaurantDetailsClick}
            >
              <div className="max-w-7xl mx-auto p-2">
                <div className="flex flex-col gap-2">
                  {/* Top row with restaurant name and login */}
                  <div className="flex justify-between items-center">
                    <h1 className="text-xl font-bold truncate max-w-[60%]">
                      {restaurantDetail?.restaurant_name}
                    </h1>

                    {showLoginButtonPaths.includes(location.pathname) &&
                      (userData ? (
                        <div className="relative">
                          <button
                            onClick={() => setShowDropdown(!showDropdown)}
                            className="flex items-center gap-1 text-sm bg-white px-2 py-1 rounded-md hover:bg-gray-50"
                          >
                            <span className="truncate max-w-[100px]">
                              {userData.customer_name}
                            </span>
                            <svg
                              className="w-3 h-3 flex-shrink-0"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </button>
                          {showDropdown && (
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                              <button
                                onClick={() => {
                                  navigate("/customer_orders", {
                                    state: { customerId: userData.customer_id },
                                  });
                                  setShowDropdown(false);
                                }}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Order Status
                              </button>
                              <button
                                onClick={() => {
                                  localStorage.removeItem("customerData");
                                  setUserData(null);
                                  setShowDropdown(false);
                                }}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Logout
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={handleLoginClick}
                          className="text-sm px-3 py-1 bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4] flex-shrink-0"
                        >
                          Login
                        </button>
                      ))}
                  </div>

                  {/* Bottom row with address details */}
                  <div
                    className={`text-sm text-gray-600 overflow-x-auto ${
                      showRestaurantDetails ? "block" : "hidden"
                    }`}
                  >
                    <div className="flex items-center space-x-2 whitespace-nowrap">
                      <span className="truncate">
                        {restaurantDetail?.address}
                      </span>
                      <span>•</span>
                      <span className="truncate">{restaurantDetail?.city}</span>
                      <span>•</span>
                      <span className="truncate">
                        {restaurantDetail?.state}
                      </span>
                      {restaurantDetail?.phone && (
                        <>
                          <span>•</span>
                          <span>
                            {restaurantDetail.phone.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "$1-$2-$3"
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Login Modal */}
        {isLoginVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
              <CustomerLogin
                onClose={handleCloseLogin}
                onLoginSuccess={handleLoginSuccess}
              />
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="min-h-screen bg-gradient-to-b from-blue-200 to-white py-8 px-4 mt-16 sm:mt-24">
          <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">
              Customer Details
            </h1>

            {isNotification && (
              <NotificationCart
                message={notificationMessage}
                status={notificationStatus}
              />
            )}

            {isLoggedIn ? (
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-700">
                  Welcome, {userData?.customer_name || "Guest"}!
                </h2>

                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-gray-700">
                    Select Your Order Type:
                  </h3>
                  <div className="flex rounded-lg bg-gray-100 p-1">
                    <button
                      type="button"
                      className={`flex-1 py-2 px-4 rounded-md transition-colors ${
                        orderType === "pickup"
                          ? "bg-[#abbfff] text-white"
                          : "hover:bg-gray-200"
                      }`}
                      onClick={() => handleOrderTypeChange("pickup")}
                    >
                      Pickup
                    </button>
                    <button
                      type="button"
                      className={`flex-1 py-2 px-4 rounded-md transition-colors ${
                        orderType === "dine-in"
                          ? "bg-[#abbfff] text-white"
                          : "hover:bg-gray-200"
                      }`}
                      onClick={() => handleOrderTypeChange("dine-in")}
                    >
                      Dine In
                    </button>
                  </div>
                </div>

                {orderType === "dine-in" && restaurantDetail && (
                  <div className="space-y-2">
                    <label className="block text-gray-700 font-medium">
                      Table Number:
                    </label>
                    <select
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={table_no}
                      onChange={(e) => settable_number(e.target.value)}
                      required
                    >
                      <option value="">Select a table</option>
                      {[...Array(restaurantDetail.number_of_tables)].map(
                        (_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}

                <div className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-700">
                    Order Details
                  </h2>
                  <ul className="space-y-2">
                    {cart.map((item) => (
                      <li
                        key={item.menu_id}
                        className="flex justify-between text-gray-700"
                      >
                        <span>
                          {item.item_name} - {item.quantity} x $
                          {item.price.toFixed(2)}
                        </span>
                        <span>${(item.price * item.quantity).toFixed(2)}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="text-right font-bold text-lg text-gray-800">
                    Total: ${calculateTotal().toFixed(2)}
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full bg-[#2563eb] text-white py-2 px-4 rounded-md ${
                      isSubmitting
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-[#4e7ab4]"
                    } transition-colors`}
                  >
                    {isSubmitting ? "Processing Order..." : "Submit Order"}
                  </button>
                </form>
              </div>
            ) : (
              <div className="space-y-6">
                {formMode === "login" && (
                  <div className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-700">
                      Log In
                    </h2>
                    {errorMessage && (
                      <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}
                    <form onSubmit={handleLogin} className="space-y-4">
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                          value={userData?.email || ""}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Password:
                        </label>
                        <input
                          type="password"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                          value={userData?.password || ""}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              password: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md"
                      >
                        Login
                      </button>
                    </form>
                    <button
                      type="button"
                      onClick={() => setFormMode("signup")}
                      className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
                    >
                      Create a new account
                    </button>
                    <button
                      type="button"
                      onClick={() => setFormMode("guestLogin")}
                      className="w-full border border-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-50 transition-colors"
                    >
                      Login as Guest
                    </button>
                  </div>
                )}

                {formMode === "forgotPassword" && (
                  <div className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-700">
                      Forgot Password
                    </h2>
                    {errorMessage && (
                      <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}
                    <form onSubmit={handleForgotPassword} className="space-y-4">
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={resetEmail}
                          onChange={(e) => setResetEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="space-y-3">
                        <button
                          type="submit"
                          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                        >
                          Send Reset Link
                        </button>
                        <button
                          type="button"
                          onClick={() => setFormMode("login")}
                          className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
                        >
                          Back to Login
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {formMode === "signup" && (
                  <div className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-700">
                      Create an Account
                    </h2>
                    {errorMessage && (
                      <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}
                    <form onSubmit={handleSignup} className="space-y-4">
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.customer_name}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              customer_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.email}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Phone Number:
                        </label>
                        <input
                          type="tel"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.phone_number}
                          onChange={(e) => {
                            const value = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 10);
                            setUserData({ ...userData, phone_number: value });
                          }}
                          pattern="[0-9]{10}"
                          maxLength="10"
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Password:
                        </label>
                        <input
                          type="password"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.password}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              password: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Confirm Password:
                        </label>
                        <input
                          type="password"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={passwordConfirmation}
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="space-y-4">
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            id="terms-signup"
                            className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            required
                          />
                          <label
                            htmlFor="terms-signup"
                            className="text-gray-700"
                          >
                            I agree to the{" "}
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowTerms(!showTerms);
                              }}
                              className="text-blue-500 hover:underline"
                            >
                              Terms and Conditions
                            </button>
                          </label>
                        </div>
                        {showTerms && (
                          <div className="bg-gray-50 p-4 rounded-md border border-gray-200 max-h-48 overflow-y-auto">
                            <h4 className="font-semibold mb-2">
                              Terms and Conditions
                            </h4>
                            <div className="space-y-2">
                              <p className="font-medium">
                                1. User Registration and Account
                              </p>
                              <ul className="list-disc pl-5 space-y-1">
                                <li>
                                  Users must provide accurate and complete
                                  information
                                </li>
                                <li>
                                  Users are responsible for maintaining account
                                  security
                                </li>
                                <li>
                                  Users must be at least 18 years old to create
                                  an account
                                </li>
                              </ul>
                              <p className="font-medium">
                                2. Order and Payment
                              </p>
                              <ul className="list-disc pl-5 space-y-1">
                                <li>
                                  All orders are subject to restaurant
                                  acceptance
                                </li>
                                <li>
                                  Prices are subject to change without notice
                                </li>
                                <li>
                                  Payment information must be valid and accurate
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="space-y-3">
                        <button
                          type="submit"
                          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
                        >
                          Sign Up
                        </button>
                        <button
                          type="button"
                          onClick={() => setFormMode("login")}
                          className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
                        >
                          Already have an account? Log In
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {formMode === "guestLogin" && (
                  <div className="space-y-4">
                    <h2 className="text-xl font-semibold text-gray-700">
                      Login as Guest
                    </h2>
                    {errorMessage && (
                      <p className="text-red-500 text-sm">{errorMessage}</p>
                    )}
                    <form onSubmit={handleGuestLogin} className="space-y-4">
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Email:
                        </label>
                        <input
                          type="email"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.email}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              email: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.customer_name || ""}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              customer_name: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-gray-700 font-medium">
                          Phone Number:
                        </label>
                        <input
                          type="tel"
                          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={userData?.phone_number}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,10}$/.test(value)) {
                              setUserData({
                                ...userData,
                                phone_number: value,
                              });
                            }
                          }}
                          maxLength="10"
                          required
                        />
                      </div>
                      <div className="space-y-4">
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            id="terms-guest"
                            className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            required
                          />
                          <label
                            htmlFor="terms-guest"
                            className="text-gray-700"
                          >
                            I agree to the{" "}
                            <button
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowTerms(!showTerms);
                              }}
                              className="text-blue-500 hover:underline"
                            >
                              Terms and Conditions
                            </button>
                          </label>
                        </div>
                        {showTerms && (
                          <div className="bg-gray-50 p-4 rounded-md border border-gray-200 max-h-48 overflow-y-auto">
                            <h4 className="font-semibold mb-2">
                              Terms and Conditions
                            </h4>
                            <div className="space-y-2">
                              <p className="font-medium">
                                1. User Registration and Account
                              </p>
                              <ul className="list-disc pl-5 space-y-1">
                                <li>
                                  Users must provide accurate and complete
                                  information
                                </li>
                                <li>
                                  Users are responsible for maintaining account
                                  security
                                </li>
                                <li>
                                  Users must be at least 18 years old to create
                                  an account
                                </li>
                              </ul>
                              <p className="font-medium">
                                2. Order and Payment
                              </p>
                              <ul className="list-disc pl-5 space-y-1">
                                <li>
                                  All orders are subject to restaurant
                                  acceptance
                                </li>
                                <li>
                                  Prices are subject to change without notice
                                </li>
                                <li>
                                  Payment information must be valid and accurate
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="w-full bg-[#abbfff] text-white py-2 px-4 rounded-md hover:bg-[#4e7ab4] transition-colors"
                      >
                        Continue as Guest
                      </button>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default CustomerDetails;
