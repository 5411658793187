import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../config";
import CustomerLogin from "./CustomerLogin";
import BackButton from "./BackButton";
import NotificationCart from "./NotificationCart";
import CartIcon from "./Carticon";

const MenuDisplay = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const [menu, setMenu] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("currentCart");
    const cartTimestamp = localStorage.getItem("cartTimestamp");

    if (savedCart && cartTimestamp) {
      const isExpired = Date.now() - parseInt(cartTimestamp) > 60 * 60 * 1000;

      if (isExpired) {
        localStorage.removeItem("currentCart");
        localStorage.removeItem("cartTimestamp");
        return [];
      }
      return JSON.parse(savedCart);
    }
    return [];
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantDetail, setRestaurantDetail] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [showCartModal, setShowCartModal] = useState(false);
  const [showRestaurantDetails, setShowRestaurantDetails] = useState(false);
  const [restaurant_id, setRestaurant_id] = useState(null);

  // Desktop event handlers
  const handleRestaurantDetailsHover = () => {
    setShowRestaurantDetails(true);
  };

  const handleRestaurantDetailsLeave = () => {
    setShowRestaurantDetails(false);
  };

  // Mobile event handler
  const handleRestaurantDetailsClick = () => {
    setShowRestaurantDetails((prevState) => !prevState);
  };

  // New states for login functionality
  const [isLoginVisible, setLoginVisible] = useState(false);
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("customerData");
    return storedData && storedData !== "undefined"
      ? JSON.parse(storedData)
      : null;
  });

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = 100; // Adjust this value based on your top header height
      setIsScrolled(window.scrollY > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const showLoginButtonPaths = ["/menu-display", "/cart", "/customer_details"];
  // Login handlers
  const handleLoginClick = () => setLoginVisible(true);
  const handleCloseLogin = () => setLoginVisible(false);
  const handleLoginSuccess = (data) => {
    setUserData(data);
    setLoginVisible(false);
  };

  // Cart position states and handlers
  const [touching, setTouching] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [cartPosition, setCartPosition] = useState(() => {
    const saved = localStorage.getItem("cartPosition");
    return saved ? JSON.parse(saved) : { x: window.innerWidth - 100, y: 20 };
  });

  const [touchPosition, setTouchPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight / 2,
  });

  // Drag handlers
  const handleDragStart = (e) => {
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    e.dataTransfer.setData("text/plain", `${offsetX},${offsetY}`);
  };

  const handleDrag = (e) => {
    if (e.clientX && e.clientY) {
      const [offsetX, offsetY] = e.dataTransfer
        .getData("text/plain")
        .split(",");
      const x = Math.min(
        Math.max(0, e.clientX - offsetX),
        window.innerWidth - 100
      );
      const y = Math.min(
        Math.max(0, e.clientY - offsetY),
        window.innerHeight - 100
      );
      setCartPosition({ x, y });
      localStorage.setItem("cartPosition", JSON.stringify({ x, y }));
    }
  };

  // Mobile touch handlers
  const handleMobileTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setDragOffset({
      x: touch.clientX - touchPosition.x,
      y: touch.clientY - touchPosition.y,
    });
  };

  const handleMobileTouchMove = (e) => {
    if (touching) {
      const touch = e.touches[0];
      const newX = Math.min(
        Math.max(0, touch.clientX - dragOffset.x),
        window.innerWidth - 80
      );
      const newY = Math.min(
        Math.max(0, touch.clientY - dragOffset.y),
        window.innerHeight - 80
      );
      setTouchPosition({ x: newX, y: newY });
    }
  };

  const handleMobileTouchEnd = () => setTouching(false);
  // added as a function to clear the cart on restaurant change.
  function handleRestaurantSwitch(restaurant_id, setCart, showNotification) {
    const previousRestaurantId = localStorage.getItem("previous_restaurant_id");

    if (
      previousRestaurantId &&
      restaurant_id &&
      previousRestaurantId !== restaurant_id
    ) {
      console.log("Cart cleared due to restaurant switch.");
      setCart([]);
      localStorage.removeItem("currentCart");
      showNotification(
        "Your cart has been cleared as you switched to a different restaurant",
        "info"
      );
    }

    if (restaurant_id) {
      localStorage.setItem("previous_restaurant_id", restaurant_id);
      console.log("Stored Restaurant ID:", restaurant_id);
    }
  }

  // Image and cart handlers
  const handleImageClick = (imageUrl) => {
    if (imageUrl) {
      setPreviewImage(imageUrl);
    }
  };
  const closePreview = () => setPreviewImage(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/customer/display-menu/${token}`
        );
        const allItems = response.data;
        setMenu(allItems.menu);
        setRestaurantDetail(allItems.restaurant);

        setRestaurant_id(allItems.restaurant.restaurant_id);

        // Call the handleRestaurantSwitch function
        // handleRestaurantSwitch(
        //   allItems.restaurant.restaurant_id,
        //   setCart,
        //   showNotification
        // );

        const uniqueCategories = [
          ...new Set(
            allItems.menu
              .filter((item) => item.category !== "todaysSpecial")
              .map((item) => item.category)
          ),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        setError("Failed to load menu. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };
    if (cart.length > 0) {
      localStorage.setItem("currentCart", JSON.stringify(cart));
      localStorage.setItem("cartTimestamp", Date.now().toString());
    }
    fetchMenu();
  }, [token, restaurant_id, cart]);

  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);

  const showNotification = (message, status) => {
    setNotificationMessage(message);
    setNotificationStatus(status);
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
    }, 9000);
  };

  // Cart management functions
  const handleAddToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
      return existingItem
        ? prevCart.map((i) =>
            i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
          )
        : [...prevCart, { ...item, quantity: 1 }];
    });
    localStorage.setItem("currentCart", JSON.stringify(cart));
  };

  const handleUpdateCart = (menuId, quantity) => {
    quantity <= 0
      ? setCart((prevCart) =>
          prevCart.filter((item) => item.menu_id !== menuId)
        )
      : setCart((prevCart) =>
          prevCart.map((item) =>
            item.menu_id === menuId ? { ...item, quantity } : item
          )
        );
    localStorage.setItem("currentCart", JSON.stringify(cart));
  };

  useEffect(() => {
    if (restaurantDetail) {
      localStorage.setItem(
        "current_restaurant_details",
        JSON.stringify(restaurantDetail)
      );
    }
  }, [restaurantDetail]);

  // useEffect(() => {
  //   localStorage.setItem("currentCart", JSON.stringify(cart));
  // }, [cart]);

  // const handleAddToCart = (item) => {
  //   setCart((prevCart) => {
  //     const existingItem = prevCart.find((i) => i.menu_id === item.menu_id);
  //     const newCart = existingItem
  //       ? prevCart.map((i) =>
  //           i.menu_id === item.menu_id ? { ...i, quantity: i.quantity + 1 } : i
  //         )
  //       : [...prevCart, { ...item, quantity: 1 }];
  //     localStorage.setItem("currentCart", JSON.stringify(newCart));
  //     return newCart;
  //   });
  // };

  // const handleUpdateCart = (menuId, quantity) => {
  //   setCart((prevCart) => {
  //     const newCart =
  //       quantity <= 0
  //         ? prevCart.filter((item) => item.menu_id !== menuId)
  //         : prevCart.map((item) =>
  //             item.menu_id === menuId ? { ...item, quantity } : item
  //           );
  //     localStorage.setItem("currentCart", JSON.stringify(newCart));
  //     return newCart;
  //   });
  // };

  const handleCheckout = () => {
    if (cart.length === 0) {
      showNotification("Your cart is empty.", "error");
      return;
    }
    navigate("/cart", { state: { cart, restaurant_id } });
  };

  const calculateTotal = () =>
    cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);

  if (isLoading) return <div className="text-center py-6">Loading menu...</div>;
  if (error)
    return <div className="text-red-500 text-center py-6">{error}</div>;

  const specialItems = menu.filter((item) => item.category === "TodaysSpecial");
  const regularItems = menu.filter((item) => item.category !== "TodaysSpecial");

  return (
    <div className="max-w-7xl mx-auto p-4 md:mt-5">
      <BackButton />
      <div className="max-w-7xl mx-auto px-4 mt-18 md:mt-15">
        {isNotification && (
          <NotificationCart
            message={notificationMessage}
            status={notificationStatus}
          />
        )}
        <div className="max-w-6xl mx-auto relative">
          {/* Floating Header Card */}
          <div
            className={`${
              isScrolled ? "fixed top-0" : "relative"
            } left-0 right-0 z-40 bg-white/95 backdrop-blur-sm shadow-lg transition-all duration-300`}
            onMouseEnter={handleRestaurantDetailsHover}
            onMouseLeave={handleRestaurantDetailsLeave}
            onClick={handleRestaurantDetailsClick} // Add click handler for mobile
          >
            <div className="max-w-7xl mx-auto p-2">
              <div className="flex flex-col gap-2">
                {/* Top row with restaurant name and login */}
                <div className="flex justify-between items-center">
                  <h1 className="text-xl font-bold truncate max-w-[60%]">
                    {restaurantDetail.name}
                  </h1>

                  {showLoginButtonPaths.includes(location.pathname) && (
                    <div className="relative">
                      {userData ? (
                        <div className="relative">
                          <button
                            onClick={() => setShowDropdown(!showDropdown)}
                            className="flex items-center gap-1 text-sm bg-white px-2 py-1 rounded-md hover:bg-gray-50"
                          >
                            <span className="truncate max-w-[100px]">
                              {userData.customer_name}
                            </span>
                            <svg
                              className="w-3 h-3 flex-shrink-0"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </button>
                          {showDropdown && (
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                              <button
                                onClick={() => {
                                  navigate("/customer_orders", {
                                    state: { customerId: userData.customer_id },
                                  });
                                  setShowDropdown(false);
                                }}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Order Status
                              </button>
                              <button
                                onClick={() => {
                                  localStorage.removeItem("customerData");
                                  setUserData(null);
                                  setShowDropdown(false);
                                }}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Logout
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={handleLoginClick}
                          className="text-sm px-3 py-1 bg-[#abbfff] text-white rounded-md hover:bg-[#4e7ab4] flex-shrink-0"
                        >
                          Login
                        </button>
                      )}
                    </div>
                  )}
                </div>

                {/* Bottom row with address details */}
                <div
                  className={`text-sm text-gray-600 overflow-x-auto ${
                    showRestaurantDetails ? "block" : "hidden"
                  }`}
                >
                  <div className="flex items-center space-x-2 whitespace-nowrap">
                    <span className="truncate">{restaurantDetail.address}</span>
                    <span>•</span>
                    <span className="truncate">{restaurantDetail.city}</span>
                    <span>•</span>
                    <span className="truncate">{restaurantDetail.state}</span>
                    {restaurantDetail.phone && (
                      <>
                        <span>•</span>
                        <span>
                          {restaurantDetail.phone.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Login Modal */}
        {isLoginVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
              <CustomerLogin
                onClose={handleCloseLogin}
                onLoginSuccess={handleLoginSuccess}
              />
            </div>
          </div>
        )}

        {/* Cart Icon */}
        {cart.length > 0 && (
          <CartIcon
            cart={cart}
            setCart={setCart}
            showCartModal={showCartModal}
            setShowCartModal={setShowCartModal}
            touching={touching}
            touchPosition={touchPosition}
            handleMobileTouchStart={handleMobileTouchStart}
            handleMobileTouchMove={handleMobileTouchMove}
            handleMobileTouchEnd={handleMobileTouchEnd}
            cartPosition={cartPosition}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            handleUpdateCart={handleUpdateCart}
            handleAddToCart={handleAddToCart}
            calculateTotal={calculateTotal}
            handleCheckout={handleCheckout}
          />
        )}

        {/* Today's Special Section */}
        {specialItems.length > 0 && (
          <div className="mb-12 relative">
            <h2 className="text-2xl font-bold text-center mb-6">
              Today's Special
            </h2>
            <div className="overflow-x-auto">
              <div className="flex space-x-6 pb-4">
                {specialItems.map((item) => (
                  <div
                    key={item.menu_id}
                    className="bg-white rounded-lg shadow-lg overflow-hidden flex-shrink-0 w-64 flex flex-col"
                  >
                    {item.image ? (
                      <img
                        src={item.image}
                        alt={item.item_name}
                        className="w-full h-48 object-cover cursor-pointer"
                        onClick={() => handleImageClick(item.image)}
                      />
                    ) : (
                      <div className="bg-gray-100 h-48 p-4 flex flex-col">
                        <h3 className="text-xl font-semibold mb-2">
                          {item.item_name}
                        </h3>
                        <p className="text-xl font-bold text-blue-600 mt-auto">
                          ${item.price}
                        </p>
                        <p className="text-blue-600 text-sm line-clamp-4 flex-1">
                          {item.description}
                        </p>
                      </div>
                    )}
                    <div className="p-4 flex-grow flex flex-col justify-end">
                      {item.image && (
                        <>
                          <h3 className="text-xl font-semibold mb-2">
                            {item.item_name}
                          </h3>
                          <p className="text-gray-600 mb-4 line-clamp-2">
                            {item.description}
                          </p>
                          <div className="flex justify-between items-center mt-auto">
                            <button
                              onClick={() => handleAddToCart(item)}
                              className="bg-[#abbfff] text-white px-4 py-2 rounded hover:bg-[#4e7ab4] transition"
                            >
                              Add to Cart
                            </button>
                            <p className="text-xl font-bold text-blue-600">
                              ${item.price}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Category Filter */}
        <div className="flex overflow-x-auto gap-4 mb-8 pb-2">
          <button
            className={`px-4 py-2 rounded-full ${
              selectedCategory === "all"
                ? "bg-[#abbfff] text-white"
                : "bg-gray-100 hover:bg-gray-200"
            }`}
            onClick={() => setSelectedCategory("all")}
          >
            All Items
          </button>

          {categories
            .filter((category) => category !== "TodaysSpecial")
            .map((category) => (
              <button
                key={category}
                className={`px-4 py-2 rounded-full ${
                  selectedCategory === category
                    ? "bg-[#abbfff] text-white"
                    : "bg-gray-100 hover:bg-gray-200"
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
        </div>

        {/* Regular Menu Items Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {regularItems
            .filter(
              (item) =>
                selectedCategory === "all" || item.category === selectedCategory
            )
            .map((item) => (
              <div
                key={item.menu_id}
                className="bg-white rounded-md shadow-sm p-2 flex flex-col h-full"
              >
                {item.image ? (
                  <>
                    <img
                      src={item.image}
                      alt={item.item_name}
                      className="w-full h-32 object-cover rounded cursor-pointer"
                      onClick={() => handleImageClick(item.image)}
                    />
                    <div className="mt-auto">
                      <div className="flex items-center justify-between text-sm mt-2">
                        <h3 className="font-semibold truncate flex-1">
                          {item.item_name}
                        </h3>
                      </div>
                      <p className="text-xs text-gray-600 line-clamp-2 my-2">
                        {item.description}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full h-32 bg-gray-100 rounded p-2">
                      <h3 className="font-semibold text-sm mb-1">
                        {item.item_name}
                      </h3>
                      <p className="text-xs text-gray-600 line-clamp-3">
                        {item.description}
                      </p>
                    </div>
                  </>
                )}
                <div className="flex items-center justify-between mt-2">
                  <div className="flex items-center space-x-2">
                    <button
                      className="px-2 py-1 text-xs bg-gray-200 rounded"
                      onClick={() =>
                        handleUpdateCart(
                          item.menu_id,
                          (cart.find((i) => i.menu_id === item.menu_id)
                            ?.quantity || 0) - 1
                        )
                      }
                    >
                      -
                    </button>
                    <span className="text-sm">
                      {cart.find((i) => i.menu_id === item.menu_id)?.quantity ||
                        0}
                    </span>
                    <button
                      className="px-2 py-1 text-xs bg-gray-200 rounded"
                      onClick={() => handleAddToCart(item)}
                    >
                      +
                    </button>
                  </div>
                  <span className="text-blue-600 font-bold">${item.price}</span>
                </div>
              </div>
            ))}
        </div>

        {/* Cart Total */}
        {cart.length > 0 && (
          <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg p-4">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
              <span className="text-xl font-bold">
                Total: ${calculateTotal()}
              </span>
              <button
                onClick={handleCheckout}
                className="bg-[#2563eb] text-white px-6 py-2 rounded hover:bg-[#4e7ab4] transition"
              >
                Checkout
              </button>
            </div>
          </div>
        )}

        {/* Image Preview Modal */}
        {previewImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closePreview}
          >
            <img
              src={previewImage}
              alt="Preview"
              className="max-w-[90%] max-h-[90vh] object-contain"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default MenuDisplay;
