import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import apiClient from "../api/ResturantClient";

const countries = [
  { name: 'United States', states: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'] },
  { name: 'Canada', states: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'] },
  { name: 'United Kingdom', states: ['England', 'Scotland', 'Wales', 'Northern Ireland'] },
  { name: 'Australia', states: ['New South Wales', 'Victoria', 'Queensland', 'Western Australia', 'South Australia', 'Tasmania', 'Australian Capital Territory', 'Northern Territory'] },
  { name: 'India', states: ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'] },
];

const UserProfile = () => {
  const user_id = parseInt(sessionStorage.getItem('user_id'));
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editableUser, setEditableUser] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/auth/user/${user_id}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile', error);
      }
    };

    fetchUser();
  }, [user_id]);

  const handleEdit = () => {
    setEditMode(true);
    setEditableUser(user);
  };

  const handleSave = async () => {
    try {
      await apiClient.put(`/auth/user/${user_id}`, editableUser);
      setUser(editableUser);
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user profile', error);
    }
  };

  const handleChange = (e) => {
    setEditableUser({ ...editableUser, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setEditableUser(prev => ({
      ...prev,
      country: selectedCountry,
      state: ''
    }));
  };

  if (!user) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-left">User Profile</h2>
        
        <div className="space-y-6">
          {editMode ? (
            <button
              onClick={handleSave}
              className="w-full py-2 px-4 bg-[#abbfff] text-white rounded-lg hover:bg-[#4e7ab4] transition-colors"
            >
              Save Changes
            </button>
          ) : (
            <button
              onClick={handleEdit}
              className="w-full py-2 px-4 bg-[#abbfff] text-white rounded-lg hover:bg-[#4e7ab4] transition-colors"
            >
              Edit Profile
            </button>
          )}

          <div className="grid gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">First Name</h3>
              {editMode ? (
                <input
                  name="first_name"
                  value={editableUser.first_name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ) : (
                <p className="text-gray-800 text-left">{user.first_name}</p>
              )}
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">Last Name</h3>
              {editMode ? (
                <input
                  name="last_name"
                  value={editableUser.last_name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ) : (
                <p className="text-gray-800 text-left">{user.last_name}</p>
              )}
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">Address</h3>
              {editMode ? (
                <input
                  name="address"
                  value={editableUser.address}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ) : (
                <p className="text-gray-800 text-left">{user.address}</p>
              )}
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">City</h3>
              {editMode ? (
                <input
                  name="city"
                  value={editableUser.city}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ) : (
                <p className="text-gray-800 text-left">{user.city}</p>
              )}
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">Country</h3>
              {editMode ? (
                <select
                  name="country"
                  value={editableUser.country}
                  onChange={handleCountryChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select a country</option>
                  {countries.map(country => (
                    <option key={country.name} value={country.name}>{country.name}</option>
                  ))}
                </select>
              ) : (
                <p className="text-gray-800 text-left">{user.country}</p>
              )}
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">State</h3>
              {editMode ? (
                <select
                  name="state"
                  value={editableUser.state}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select a state</option>
                  {countries.find(c => c.name === editableUser.country)?.states.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
              ) : (
                <p className="text-gray-800 text-left">{user.state}</p>
              )}
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">Zip Code</h3>
              {editMode ? (
                <input
                  name="zip_code"
                  value={editableUser.zip_code}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ) : (
                <p className="text-gray-800 text-left">{user.zip_code}</p>
              )}
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">Email</h3>
              <p className="text-gray-800 text-left">{user.email}</p>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-1 text-left">Phone</h3>
              <p className="text-gray-800 text-left">{user.phone}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;