import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";

const SelectSubscription = ({ history }) => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(""); // Add message state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/subscription/plans`
        );
        setPlans(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscription plans", error);
        setMessage("Error loading subscription plans. Please try again.");
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = async (planId) => {
    try {
      const userId = sessionStorage.getItem("user_id");
      const response = await axios.post(
        `${config.API_BASE_URL}/subscription/select-plan`,
        {
          user_id: userId,
          subscription_id: planId,
        }
      );
      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      console.error("Error selecting subscription plan", error);
      setMessage("Error selecting subscription plan. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-12">
          Select Your Perfect Plan
        </h2>

        {/* Add notification message */}
        {message && (
          <div className="mt-4 p-4 bg-blue-50 border border-red-200 rounded-lg mb-6">
            <p className="text-blue-600 text-center text-sm">{message}</p>
          </div>
        )}

        {loading ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="text-xl font-semibold text-gray-700">
              Loading plans...
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {plans.map((plan) => (
              <div
                key={plan.subscription_id}
                className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
              >
                <div className="p-8">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">
                    {plan.subscription_name}
                  </h3>
                  <div className="flex items-baseline mb-4">
                    <span className="text-4xl font-extrabold text-gray-900">
                      ${plan.price}
                    </span>
                    <span className="ml-2 text-gray-500">
                      / {plan.duration_months} months
                    </span>
                  </div>
                  <button
                    onClick={() => handleSelectPlan(plan.subscription_id)}
                    className="w-full bg-blue-600 text-white rounded-lg px-4 py-3 font-semibold
                             hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 
                             focus:ring-offset-2 transition-colors duration-200"
                  >
                    Select Plan
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectSubscription;
