import React, { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import config from "../config";

const DailySalesReport = ({ restaurant_id }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [salesData, setSalesData] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const fetchSalesData = async () => {
    try {
      if (!restaurant_id) {
        console.error("Restaurant ID is missing");
        return;
      }
      if (!selectedDate) {
        console.error("Date is required");
        return;
      }

      const response = await axios.get(
        `${config.API_BASE_URL}/customer/daily-sales/${restaurant_id}`,
        { params: { date: selectedDate } }
      );
      setSalesData(response.data);
      setShowReport(true);
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setSalesData(null);
    }
  };

  const exportToCSV = () => {
    if (!salesData) return;

    const csvContent = [
      ["Order ID", "Table Number", "Status", "Total Amount", "Date"],
      ...salesData.orders.map((order) => [
        order.order_id,
        order.table_number || "Pick-up",
        order.status,
        order.total_amount,
        order.created_at,
      ]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Daily_Sales_${selectedDate}.csv`);
  };

  return (
    <div>
      <button
        onClick={() => setShowDatePicker(!showDatePicker)}
        className="bg-[#abbfff] text-white px-4 py-2 rounded-md"
      >
        Sales Report
      </button>

      {showDatePicker && (
        <div className="date-picker-container mt-4 p-4 bg-gray-100 rounded-md shadow-md">
          <h2 className="text-lg font-semibold">
            Select Date for Sales Report
          </h2>
          <label className="block mb-2 text-sm font-medium">Select Date:</label>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="border border-gray-300 rounded-md p-2"
          />
          <button
            onClick={fetchSalesData}
            className="ml-2 bg-green-500 text-white px-4 py-2 rounded-md"
          >
            Fetch Report
          </button>
        </div>
      )}

      {showReport && salesData && (
        <div className="fixed top-10 right-10 bg-white shadow-lg rounded-lg p-4 w-96 max-h-96 overflow-y-auto z-50">
          <button
            className="absolute top-2 right-2 text-gray-600"
            onClick={() => setShowReport(false)}
          >
            ✖
          </button>
          <h3 className="text-lg font-semibold mb-4">Daily Sales Report</h3>
          <h4>Total Revenue: ${salesData.total_revenue.toFixed(2)}</h4>
          <h4>Total Orders: {salesData.total_orders}</h4>
          <button
            onClick={exportToCSV}
            className="mt-2 bg-green-500 text-white px-4 py-2 rounded-md"
          >
            Export to CSV
          </button>
          <table className="table-auto w-full mt-4 border border-gray-300">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Table Number</th>
                <th>Status</th>
                <th>Total Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {salesData.orders.map((order) => (
                <tr key={order.order_id}>
                  <td>{order.order_id}</td>
                  <td>{order.table_number || "Pick-up"}</td>
                  <td>{order.status}</td>
                  <td>${order.total_amount.toFixed(2)}</td>
                  <td>{order.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DailySalesReport;
