// import React from "react";
import React, { useEffect } from "react";

const CartIcon = ({
  cart,
  showCartModal,
  setShowCartModal,
  touching,
  touchPosition,
  handleMobileTouchStart,
  handleMobileTouchMove,
  handleMobileTouchEnd,
  cartPosition,
  handleDragStart,
  handleDrag,
  handleUpdateCart,
  handleAddToCart,
  calculateTotal,
  handleCheckout,
  setCart,
}) => {
  useEffect(() => {
    if (cart.length > 0) {
      const clearCartTimer = setTimeout(() => {
        setCart([]);
        setShowCartModal(false);
        localStorage.removeItem("cart"); // If you're using localStorage
      }, 60 * 60 * 1000);

      return () => clearTimeout(clearCartTimer);
    }
  }, [cart]);

  return (
    <>
      {/* Mobile Cart Icon */}
      <div
        className="fixed z-50 touch-none block md:hidden"
        style={{
          top: touchPosition.y,
          left: touchPosition.x,
          transform: "translate3d(0, 0, 0)",
          touchAction: "none",
        }}
        onTouchStart={handleMobileTouchStart}
        onTouchMove={handleMobileTouchMove}
        onTouchEnd={handleMobileTouchEnd}
      >
        <div className="flex flex-col items-center">
          <div
            className="relative cursor-pointer bg-white p-3 rounded-full shadow-lg hover:shadow-xl transition-shadow"
            onClick={() => !touching && setShowCartModal(!showCartModal)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-[#abbfff]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
              {cart.reduce(
                (total, item) => total + (item.menu_id ? item.quantity : 0),
                0
              )}
            </span>
          </div>
          <span className="text-sm font-medium text-gray-700 mt-1">
            Your Cart
          </span>
        </div>
      </div>

      {/* Desktop Cart Icon */}
      <div
        className="fixed z-50 touch-none hidden md:block"
        style={{
          top: cartPosition.y,
          left: cartPosition.x,
          cursor: "move",
          transform: "translate3d(0, 0, 0)",
          touchAction: "none",
        }}
        draggable="true"
        onDragStart={handleDragStart}
        onDrag={handleDrag}
      >
        <div className="flex flex-col items-center">
          <div
            className="relative cursor-pointer bg-white p-3 rounded-full shadow-lg hover:shadow-xl transition-shadow"
            onClick={() => setShowCartModal(!showCartModal)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-[#abbfff]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
              {cart.reduce(
                (total, item) => total + (item.menu_id ? item.quantity : 0),
                0
              )}
            </span>
          </div>
          <span className="text-sm font-medium text-gray-700 mt-1">
            Your Cart
          </span>
        </div>
      </div>

      {/* Cart Modal with Details */}
      {showCartModal && (
        <div className="fixed right-4 top-16 w-80 bg-white rounded-lg shadow-xl z-50 p-4 max-h-[80vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">Cart Items</h3>
            <button
              onClick={() => setShowCartModal(false)}
              className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {cart.length === 0 ? (
            <p className="text-center text-gray-500 py-4">Your cart is empty</p>
          ) : (
            <>
              <div className="space-y-4">
                {cart.map((item) =>
                  item.menu_id ? (
                    <div
                      key={item.menu_id}
                      className="flex items-center justify-between border-b pb-3"
                    >
                      <div className="flex-1">
                        <p className="font-semibold text-sm">
                          {item.item_name}
                        </p>
                        <p className="text-sm text-gray-600">
                          ${item.price} x {item.quantity}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          className="px-2 py-1 bg-gray-100 rounded hover:bg-gray-200"
                          onClick={() =>
                            handleUpdateCart(item.menu_id, item.quantity - 1)
                          }
                        >
                          -
                        </button>
                        <span className="w-8 text-center">{item.quantity}</span>
                        <button
                          className="px-2 py-1 bg-gray-100 rounded hover:bg-gray-200"
                          onClick={() => handleAddToCart(item)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ) : null
                )}
              </div>

              <div className="border-t pt-3 mt-3">
                <div className="flex justify-between items-center mb-4">
                  <span className="font-semibold">Subtotal:</span>
                  <span className="font-bold">${calculateTotal()}</span>
                </div>
                <button
                  onClick={handleCheckout}
                  className="w-full bg-[#2563eb] text-white py-2 rounded hover:bg-[#4e7ab4] transition-colors font-medium"
                >
                  Proceed to Checkout
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CartIcon;
