import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NotificationCart from "./NotificationCart";
import apiClient from "../api/ResturantClient";

const MenuManagement = () => {
  const restaurant_id = sessionStorage.getItem("restaurant_id");
  const [menuItems, setMenuItems] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [formData, setFormData] = useState({
    item_name: "",
    description: "",
    price: 0.0,
    category: "",
    image: "",
    available: true,
  });
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showEnableButton, setShowEnableButton] = useState(false);

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const showNotification = (message, status) => {
    setNotificationMessage(message);
    setNotificationStatus(status);
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
    }, 9000);
  };

  const [buttonClicked, setButtonClicked] = useState(
    sessionStorage.getItem("dashboardEnabled") === "true"
  );

  const fetchMenuItems = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `/menu/get-menu-items/${restaurant_id}`
      );
      setMenuItems(response.data);
      setShowEnableButton(response.data.length > 0 && !buttonClicked);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleEnableDashboard = () => {
    sessionStorage.setItem("dashboardEnabled", "true");
    setButtonClicked(true);
    window.location.reload();
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (name === "price") {
      const numValue = Number(value);
      if (numValue < 0) return;

      setFormData({
        ...formData,
        price: numValue || "",
      });
      return;
    }

    setFormData({
      ...formData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });

    if (name === "image") {
      setImagePreview(URL.createObjectURL(files[0]));
    }
  };

  const handleImageUpload = async (imageFile) => {
    try {
      const fileName = encodeURIComponent(imageFile.name);
      const response = await apiClient.post(`/menu/generate-signed-url`, {
        file_name: fileName,
      });
      const signedUrl = response.data.signed_url;
      await axios.put(signedUrl, imageFile, {
        headers: {
          "Content-Type": imageFile.type,
          "x-amz-acl": "public-read",
        },
      });

      return signedUrl.split("?")[0];
    } catch (error) {
      console.log("Error uploading image:", error);

      throw new Error("Image upload failed");
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      let imageUrl = formData.image;

      if (formData.image instanceof File) {
        imageUrl = await handleImageUpload(formData.image);
      }

      const formDataToSend = {
        ...formData,
        image: imageUrl,
        restaurant_id,
      };

      const endpoint = formData.menu_id
        ? `/menu/update-menu-item/${formData.menu_id}`
        : `/menu/add-menu-item`;

      await apiClient.post(endpoint, formDataToSend);

      setFormData({
        item_name: "",
        description: "",
        price: 0.0,
        category: "",
        image: "",
        available: true,
      });
      setImagePreview(null);
      if (fileInputRef.current) fileInputRef.current.value = "";

      showNotification(
        formData.menu_id
          ? "Menu item updated successfully"
          : "Menu item added successfully",
        "success"
      );
      setShowEnableButton(true);
    } catch (error) {
      showNotification("Error adding/updating menu item", "error");
    } finally {
      await fetchMenuItems();
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (menu_id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await apiClient.delete(`/menu/delete-menu-item/${menu_id}`);
        setMenuItems(menuItems.filter((item) => item.menu_id !== menu_id));
        showNotification("Menu item deleted successfully", "success");
      } catch (error) {
        showNotification("Error deleting menu item", "error");
      }
    }
  };

  const handleEdit = (item) => {
    setFormData({
      menu_id: item.menu_id,
      item_name: item.item_name,
      description: item.description,
      price: parseFloat(item.price),
      category: item.category,
      available: item.available,
      image: item.image,
    });
    setImagePreview(item.image);
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
        Menu Management
      </h2>

      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}

      {showEnableButton && !buttonClicked && (
        <button
          onClick={handleEnableDashboard}
          className="w-full max-w-6xl mx-auto mb-6 py-3 px-4 bg-[#abbfff] hover:bg-[#4e7ab4] text-white font-semibold rounded-lg shadow-md"
        >
          Enable All Dashboard Features
        </button>
      )}
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-lg p-6 mb-8"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Item Name
              </label>
              <input
                type="text"
                name="item_name"
                value={formData.item_name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Description
              </label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 h-32"
              />
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Price
              </label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                min="0"
                step="any"
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Category
              </label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select a category</option>
                <option value="Starter">Starter</option>
                <option value="MainCourse">Main Course</option>
                <option value="Dessert">Dessert</option>
                <option value="Beverage">Beverage</option>
                <option value="TodaysSpecial">Today's Special</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Image
              </label>
              <input
                type="file"
                name="image"
                onChange={handleChange}
                accept="image/*"
                ref={fileInputRef}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                className="w-full h-48 object-cover rounded-lg"
              />
            )}

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="available"
                checked={formData.available}
                onChange={handleChange}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label className="text-sm font-medium text-gray-700">
                Available
              </label>
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full mt-6 py-3 px-4 ${
            isSubmitting ? "bg-gray-400" : "bg-[#abbfff] hover:bg-[#4e7ab4]"
          } text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors`}
        >
          {isSubmitting
            ? "Processing..."
            : formData.menu_id
            ? "Update Menu Item"
            : "Add Menu Item"}
        </button>
      </form>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {menuItems.map((item) => (
            <div
              key={item.menu_id}
              className="bg-white rounded-xl shadow-lg overflow-hidden h-full"
            >
              {item.image ? (
                <img
                  src={item.image}
                  alt={item.item_name}
                  className="w-full h-48 object-cover"
                />
              ) : (
                <div className="bg-gray-100 p-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-3">
                    {item.item_name}
                  </h3>
                  <p className="text-gray-600 text-lg mb-3">
                    {item.description}
                  </p>
                  <p className="text-2xl font-bold text-blue-600">
                    ${item.price}
                  </p>
                </div>
              )}
              <div className={`p-4 ${!item.image ? "mt-auto" : ""}`}>
                {item.image && (
                  <>
                    <h3 className="text-lg font-semibold text-gray-800">
                      {item.item_name}
                    </h3>
                    <p className="text-gray-600 mt-1">{item.description}</p>
                    <p className="text-lg font-bold text-blue-600 mt-2">
                      ${item.price}
                    </p>
                  </>
                )}
                <div className="flex gap-2 mt-4">
                  <button
                    onClick={() => handleEdit(item)}
                    className="flex-1 py-2 bg-[#abbfff] text-white rounded hover:bg-[#4e7ab4] transition-colors"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(item.menu_id)}
                    className="flex-1 py-2 bg-[#abbfff] text-white rounded hover:bg-[#4e7ab4] transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuManagement;
