import { io } from 'socket.io-client';
import config from '../../config';

// Create a WebSocket connection to the server
let socket = null;

export const initializeSocket = (restaurantId) => {
  // Only initialize the socket if it's not already initialized
  if (!socket) {
    socket = io(config.SOCKET_URL, {
      transports: ['websocket'],
      query: { restaurant_id: restaurantId },
    });

    // Join a room based on the restaurant ID
    socket.emit('join_room', { restaurant_id: restaurantId });
  }
  return socket;
};

export const subscribeToOrderUpdates = (restaurantId, callback) => {
  const socketInstance = initializeSocket(restaurantId);

  socketInstance.on('order_update', (orderData) => {
    callback(orderData); // Handle the received order data
  });
};

export const subscribeToNewOrder = (restaurantId, callback) => {
  const socketInstance = initializeSocket(restaurantId);
  socketInstance.on('new_order', (orderData) => {
    callback(orderData); // Handle the new order data
  });
};

export const sendMessage = (event, data) => {
  if (socket) {
    socket.emit(event, data);
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
